import React from "react";
import CookieConsent from "react-cookie-consent";

import createPanZoom from "panzoom";

import {
  PageContainer, ZoomContainer,
  color2, color4, color3,
  ClosePageButton, ClosePageButtonText,
} from "./Components";

import {Menu} from "./Menu";
import {Glass} from "./Glass";
import {Story} from "./Story";
import {FullScreenStory} from "./FullScreenStory";
import {Imprint} from "./Imprint";
import {Experts} from "./Experts";
import {InformationPage} from "./InformationPage";

import brunner01 from "../images/01_BirgitBrunner.jpg";
import brunner02 from "../images/02_BirtigBrunner.jpg";

import mueller01 from "../images/01_DanielaMüller.jpg";
import mueller02 from "../images/02_DanielaMüller.jpg";
import mueller03 from "../images/03_DanielaMüller.jpg";

import baumann01 from "../images/01_KarlBaumann.jpg";
import baumann02 from "../images/02_KarlBaumann.jpg";

import meissner01 from "../images/01_LuisaMeißner.jpg";
import meissner02 from "../images/02_LuisaMeißner.jpg";
import meissner03 from "../images/03_LuisaMeißner.jpg";

import rommel01 from "../images/01_OttoRommel.jpg";
import rommel02 from "../images/02_OttoRommel.jpg";
import rommel03 from "../images/03_OttoRommel.jpg";

import makowsky01 from "../images/01_TheresaMakowsky.jpg";
import makowsky02 from "../images/02_TheresaMakowsky.jpg";
import makowsky03 from "../images/03_TheresaMakowsky.jpg";

import vollbracht01 from "../images/01_UteBauerVollbracht.jpg";
import vollbracht02 from "../images/02_UteBauerVollbracht.jpg";
import vollbracht03 from "../images/03_UteBauerVollbracht.jpg";

import gerteisen01 from "../images/01_WolfgangGerteisen.jpg";
import gerteisen02 from "../images/02_WolfgangGerteisen.jpg";

import reimers01 from "../images/01_LennartReimers.jpg";
import reimers02 from "../images/02_LennartReimers.jpg";
import reimers03 from "../images/03_LennartReimers.jpg";

export class LongCovid extends React.Component {

  constructor() {
    super();
    this.state = {
      stories: null,
      currentStory: null,
      glassVisible: true,
      closePageButtonVisible: false,
      informationVisible: false,
      imprintVisible: false,
      expertVisible: false
    }
  }

  componentDidMount() {
    this.initMatomo();

    let centerCenter = {x: 0.5, y: 0.5};
    const element = document.querySelector('#zoom-container');
    this.pzoom = createPanZoom(element, {
      transformOrigin: centerCenter,
      zoomSpeed: 0.2,
      maxZoom: window.innerWidth > 1000 ? 1 : 0.8,
      minZoom: 0.3,
      bounds: true,
      boundsPadding: 0.7,
      initialZoom: window.innerWidth > 1000 ? 0.8 : 0.6
    });

    this.setState({
      language: 'DE',
      stories: this.stories
    });

    this.timeout = setTimeout(() => {
      this.setState({glassVisible: false});
    }, 8000);
  }

  componentWillUnmount() {
    if (this.timeout){
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  toggleInformation() {
    if (this.timeout){
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (!this.state.informationVisible){
      this.trackPageView("Information");
    };
    this.hidePersonList();
    this.setState({
      currentStory: null,
      informationVisible: !this.state.informationVisible,
      imprintVisible: false,
      expertVisible: false,
      closePageButtonVisible: this.state.currentStory ? false: !this.state.informationVisible,
      glassVisible: !this.state.informationVisible,
    });
  }

  toggleImprint(){
    if (this.timeout){
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (!this.state.imprintVisible){
      this.trackPageView("Impressum");
    };
    this.hidePersonList();
    this.setState({
      currentStory: null,
      informationVisible: false,
      imprintVisible: !this.state.imprintVisible,
      expertVisible: false,
      closePageButtonVisible: this.state.currentStory ? false : !this.state.imprintVisible,
      glassVisible: this.state.currentStory ? false : this.state.glassVisible
    });
  }

  toggleExpert(){
    if (this.timeout){
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (!this.state.expertVisible){
      this.trackPageView("Experten");
    };
    this.hidePersonList();
    this.setState({
      currentStory: null,
      informationVisible: false,
      imprintVisible: false,
      expertVisible: !this.state.expertVisible,
      closePageButtonVisible: this.state.currentStory ? false: !this.state.expertVisible,
      glassVisible: !this.state.expertVisible,
    });
  }

  toggleStory(story, event) {
    if (this.timeout){
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (event) {
      event.stopPropagation();
    }
    this.trackPageView("Story - " + story?.key);
    this.hidePersonList();
    this.setState({
      currentStory: story,
      informationVisible: false,
      imprintVisible: false,
      expertVisible: false,
      closePageButtonVisible: true,
      glassVisible: true,
    });
  }

  hidePersonList(){
    this.menuRef.hideList();
  }

  onClosePageButton(){
    if (this.timeout){
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.hidePersonList();
    this.setState({
      currentStory: null,
      informationVisible: false,
      imprintVisible: false,
      expertVisible: false,
      closePageButtonVisible: false,
      glassVisible: false
    });
  }

  onCookiesAccepted(){
    window.localStorage.setItem('consent', "true");
  }

  onCookiesDeclined(){
    window.localStorage.setItem('consent', "false");
  }


  initMatomo(){
    if (!(window.localStorage.getItem("consent") === "false")){
      window._paq = window._paq || [];
      window._paq.push(['trackPageView']);
      window._paq.push(['enableLinkTracking']);
      (function() {
        var u="//www.thereisgloryinprevention.de/analytics/";
        window._paq.push(['setTrackerUrl', u+'matomo.php']);
        window._paq.push(['setSiteId', '2']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    }
  }

  trackPageView(page){
    if (!(window.localStorage.getItem("consent") === "false")){
      const currentUrl = '/' + page;
      window._paq.push(['setCustomUrl', currentUrl]);
      window._paq.push(['setGenerationTimeMs', 0]);
      window._paq.push(['trackPageView']);
    }
  }

  centerOn = (ui, parent="page-container") => {
    this.pzoom.smoothMoveTo(0, 0, true);

    // window.setTimeout(() => { pzoom.smoothZoomAbs(0.5,0.5,1) }, 500);
    window.setTimeout(() => {
      ui = document.querySelector('#'+ui);
      parent = document.querySelector('#'+parent);

      const clientRect = ui.getBoundingClientRect();
      const cx = clientRect.left + clientRect.width / 2;
      const cy = clientRect.top + clientRect.height / 2;
      const container = parent.getBoundingClientRect();
      const dx = container.width / 2 - cx;
      const dy = container.height / 2 - cy;
      this.pzoom.smoothMoveTo(dx, dy, true);
    }, 400);

    this.onClosePageButton();
  }

  get positions(){
    const positions = [
      {top: -50, left: -20},
      {top: 250, left: 860},
      {top: 1100, left: -150},
      {top: -200, left: 1880},
      {top: 950, left: 1680},
      {top: 530, left: 2980},
      {top: 1500, left: 780},
      {top: 1700, left: 2780},
      {top: 2000, left: 1800},
    ];

    return positions;
  }

  get stories(){
    const storiesDe = [
      {
        key: 'TheresaMakowsky',
        img: makowsky01,
        images: [makowsky03, makowsky02, makowsky01],
        text: '»Jedes Mal beim Haarewaschen habe ich gesehen, wie im Abfluss die Büschel verschwunden sind.«',
        texts: [
          "»Ich habe mir letzten Sommer so oft gewünscht, ich hätte diese Krankheit nicht gehabt. Es war, als würde jemand mit der Hand auf meinen Brustkorb drücken, um mir das Atmen zu erschweren.«",
          "»Es kann doch nicht wahr sein, dass mich eine Krankheit auch nach Monaten so fühlen lässt, als wäre ich einen Marathon gelaufen.«",
          "Theresa Makowsky hat sich Ende Februar 2020 in einem Berliner Club angesteckt. Kurz vor dem ersten Lockdown galt sie bereits als genesen. Doch Langzeitfolgen begleiteten sie noch über ein Jahr: Schmerzen in der Lunge, Geruchsirritationen, Müdigkeit und Haarausfall.",
        ],
        textNovember: "November 2021: »Mir geht es schon viel besser. Ich habe zwar immer noch starken Haarausfall, aber die Kurzatmigkeit ist nicht mehr da. Vielleicht ist es auch durch den Stress, da ich kurz vor meinem Examen stehe. Ich habe die große Hoffnung, dass ich in einem halben Jahr wieder richtig fit bin.«",
        name: 'Theresa Makowsky, 26, Berlin',
        nameShort: 'Theresa Makowsky',
        audio: "/audio/TheresaMakowsky_02Version_frei.mp3",
      },
      {
        key: 'UteBauerVollbracht',
        img: vollbracht01,
        images: [vollbracht02, vollbracht03, vollbracht01],
        text: '»Ich habe nur noch geschlafen. Und irgendwann konnte ich auch nicht mehr schlafen, obwohl ich müde war.«',
        texts: [
          "Ute Bauer-Vollbracht liebt ihre Arbeit in einem Wohnheim für Menschen mit Behinderung. Früher schaffte sie es, alle 12 BewohnerInnen gleichzeitig zu betreuen. Normalerweise springt sie gerne am Wochenende oder für Nachtdienste ein. Heute ist sie froh, wenn sie es überhaupt zur Arbeit schafft. Schon das Ausräumen ihrer Spülmaschine stellt sie vor eine Herausforderung.",
          "»Ich hatte keine Kraft mehr, die Treppe hochzugehen. Es kam mir vor, wie auf einen Berg zu steigen. Die Beine, die Arme und der Kopf. Nichts will mehr. Der ganze Körper macht nicht mehr mit.«",
          "Bei Ute Bauer-Vollbracht wurde Anfang April eine gedeckte Perforation entdeckt. Sie musste am Darm notoperiert werden und hat sich dann wahrscheinlich im Krankenhaus mit Corona infiziert. Nach ihrer akuten Erkrankung ging es ihr wieder besser, doch ab August 2020 wurde sie jeden Tag schwächer. Kopfschmerzen, Atemnot, Wortfindungsschwierigkeiten und Gliederschmerzen gehören zu ihrem Alltag.",
        ],
        textNovember: "",
        name: 'Ute Bauer-Vollbracht, 55, Mechterstädt',
        nameShort: 'Ute Bauer-Vollbracht',
        audio: "/audio/UteBauerVollbracht_02Version_frei.mp3",
      },
      {
        key: 'KarlBaumann',
        img: baumann01,
        images: [baumann02, baumann01],
        text: '»Ich war permanent auf Vollgas. Ich bin nur am Arbeiten gewesen. Das Leben von früher gibt es eigentlich nicht mehr.«',
        texts: [
          "Karl Baumann bei einer Untersuchung im Krankenhaus in Donaustauf. Seine Werte zeigen keine Auffälligkeiten. Aber er hat zahlreiche Langzeitfolgen. Er ist vergesslich und fühlt sich schlapp. Seine Frau muss heute die Getränkekisten in die Wohnung tragen.",
          "Als Karl Baumann im März 2020 ins künstliche Koma gesetzt wird, tragen die Pflegekräfte noch nicht einmal Schutzkleidung. Als er aufwacht, denkt er, sie hätten ihn auf den Mars geschossen. Nach seiner fünfwöchigen Reha geht es ihm immer noch nicht besser. Baumann sucht nach einer Selbsthilfegruppe. Er wird nicht fündig und gründet kurzerhand selbst eine Gruppe für Patienten mit Langzeitfolgen und deren Angehörigen. »Ich habe erst im Januar 2021 realisiert, wie krank ich wirklich war.« Die Zeit auf der Intensivstation musste er in einer Psychotherapie aufarbeiten.",
        ],
        textNovember: "November 2021: »Heute kann ich besser gehen und die Atemnot ist weg. Aber ich bin immer noch sehr müde. Ende November bin ich noch einmal in einer Reha. Mal schauen, was sich da noch verbessert.«",
        name: 'Karl Baumann, 53, Wenzenbach',
        nameShort: 'Karl Baumann',
        audio: "/audio/KarlBaumann_02Version_frei.mp3",
      },
      {
        key: 'LuisaMeissner',
        img: meissner01,
        images: [meissner02, meissner03, meissner01],
        text: '»Menschen mit Corona-Langzeitfolgen bekommen keine Hilfe. Das ist für mich unerklärlich.«',
        texts: [
          "Die Reittherapie hilft Luisa Meißner, ihr körperliches und seelisches Trauma aufzuarbeiten. Sie und ihre Mutter fühlen sich oft alleingelassen. Viele Therapien werden nicht von der Kasse übernommen. Das macht es der jungen Frau schwer zu genesen.",
          "Durch Covid-19 und die Zeit auf der Intensivstation entwickelt Luisa Meißner die Symptome einer Ataxie, eine Störung der Kleinhirnfunktion. Ein Jahr nach ihrer Erkrankung kann sie nur wenige Meter mit der Hilfe von Stöcken gehen. Oft genügt schon eine kleine Anstrengung und sie kann sich nicht mehr auf den Beinen halten kann. Wenn ungeplante Wartezeiten entstehen, setzt sie sich oft einfach auf den Boden.",
          "Luisa Meißner machte ein Erasmus-Praktikum in Sevilla in Spanien und steckte sich dort mit dem Virus an. Sie verbrachte 32 Tage im künstlichen Koma und konnte erst dann zurück nach Deutschland geflogen werden: »Am Anfang haben die Ärzte teilweise geschätzt, dass ich mindestens fünf Jahre brauchen werde um wieder ganz gesund zu werden. Andere haben mir die Hoffnung darauf eher genommen.«",
        ],
        textNovember: "November 2021: »Beim Gehen habe ich einige Fortschritte gemacht. Das Stehen funktioniert immer noch schlecht. Auch den Brain Fog und die Vergesslichkeit habe ich immer noch.«",
        name: 'Luisa Meißner, 23, Düsseldorf',
        nameShort: 'Luisa Meißner',
        audio: '/audio/LuisaMeissner_DritteVersion_Frei.mp3',
      },
      {
        key: 'DanielaMueller',
        img: mueller01,
        images: [mueller02, mueller03, mueller01],
        text: '»Ich hatte über ein Jahr Schlafstörungen, weil ich Angst hatte, nachts das Atmen zu vergessen.«',
        texts: [
          "»Es fühlt sich an, als würde man morgens mit einer nur zehn Prozent geladenen Batterie aufstehen.«",
          "Vor einem Jahr hat sich Daniela Müller mit dem Corona-Virus angesteckt. Viele Behandlungen und Therapien muss sie sich selbst erkämpfen und einfordern. »Mit Long Covid war ich der Systemsprenger, da ich nicht einer Fachrichtung zugeordnet werden konnte. Durch ein angepasstes Sportprogramm habe ich wieder Vertrauen in meinen Körper gewonnen.«",
          "Daniela Müller hat schon einmal eine Pandemie miterlebt. Sie hat in Mexiko gelebt, als dort 2009 die Schweinegrippe ausbrach. Deswegen hat sie sich Anfang 2020 keine Sorgen gemacht. Noch vor dem ersten Lockdown hatte sie sich auf der Arbeit angesteckt.",
        ],
        textNovember: "November 2021: »Die Fatigue ist nach der ersten Impfung verschwunden. Mittlerweile lebe ich fast wieder normal. Meine Muskeln machen immer noch Probleme. Auch durch regelmäßiges Training baue ich keine Kraft auf und habe oft Muskelschmerzen.«",
        name: 'Daniela Müller, 46, Stuttgart',
        nameShort: 'Daniela Müller',
        audio: "/audio/DanielaMüller03_FREI.mp3",
      },
      {
        key: 'WolfgangGerteisen',
        img: gerteisen01,
        images: [gerteisen02, gerteisen01],
        text: '»Mein Leben vor Corona war sehr erfüllt. Ich war nie krank. Arztpraxen kannte ich nur vom Hörensagen.«',
        texts: [
          "Lange war nicht klar, ob Wolfgang Gerteisen überleben wird. Nach der künstlichen Beatmung musste er erst wieder Schlucken und Sprechen lernen. Heute kann er wieder 20 Kilometer am Stück Wandern, geht Schwimmen und fährt Mountainbike.",
          ["Wolfgang Gerteisen lag 87 Tage auf der Intensivstation. Anfang März 2020 hat er sich in einem Skigebiet in Frankreich angesteckt: »Die Gefahr war präsent, aber ich habe sie unterschätzt.«", <br/>, <br/>, "Husten, Schlafstörungen und Vernarbungen in der Lunge bleiben. Aber er kann wieder ein selbstbestimmtes Leben führen."],
        ],
        textNovember: "November 2021: »Ich bin gerade dabei, meine Medikamente zu reduzieren. Den Husten habe ich zwar immer noch, dennoch habe ich alle meine Ziele erreicht und fühle mich wohl. Die Treppe vor meinem Haus fahre ich jetzt sogar mit dem Mountainbike hoch.«",
        name: 'Wolfgang Gerteisen, 66, Münstertal',
        nameShort: 'Wolfgang Gerteisen',
        audio: "/audio/WolfgangGerteisen_ErsteVersion_frei.mp3",
      },
      {
        key: 'BirgitBrunner',
        img: brunner01,
        images: [brunner02, brunner01],
        texts: [
          "Oft vergisst Birgit Brunner einfache Dinge, wie die Zutaten für eine Lasagne. Von vielen Ärzten werden die Symptome von ihr und ihrer Tochter nicht ernst genommen. Durch einen Hilferuf in Facebook-Gruppen ist sie auf einen Neurologen in Wien und einen Pulmologen, einen Experten für Lungenheilkunde, in Deutschland gekommen die ihre Symptome ernst nehmen und zuhören.",
          "Birgit Brunner ist Anfang März 2020 gemeinsam mit ihrer 10-jährigen Tochter an Covid-19 erkrankt. Beide leiden noch heute unter Erschöpfungszuständen, einem geschwächten Immunsystem und Brain Fog.",
        ],
        textNovember: "November 2021: »Nach einem online Atemkurs kann ich besser sprechen und muss nicht mehr sooft nach Luft schnappen. Doch ständig kommt etwas Neues dazu. Gerade habe ich mit Venenentzündungen in den Beinen zu kämpfen. Sophie hat weiterhin schwere Rückschläge. Nach einer Stunde Sportunterricht geht erst einmal nichts mehr.«",
        text: '»Es wird nicht mehr so, wie es vorher war. Ich habe lange gebraucht, um das zu akzeptieren.«',
        name: 'Birgit Brunner, 51, Höchst, Vorarlberg',
        nameShort: "Birgit Brunner",
        audio: "/audio/BirgitBrunner_ErsteVersion_frei.mp3",
      },
      {
        key: 'OttoRommel',
        img: rommel01,
        images: [rommel02, rommel03, rommel01],
        text: '»Ich hätte nie gedacht, dass ich kurz vor einem Organversagen stehe.«',
        texts: [
          "Otto Rommel in einer Praxis für integrative Medizin und Faszientherapie. Er hat viele traumatische Erinnerungen von der Zeit auf der Intensivstation und leidet unter Schlafstörungen und Panikattacken.",
          ["Otto Rommel geht mit seinem Hund Pyron spazieren. Gemeinsam mit weiteren Betroffenen von Long Covid gründete er eine Interessen- vertretung:", <br/>, <br/>, "»Ich denke, die Probleme von Long Covid sind für die Gesellschaft noch schwerer zu verstehen als die Corona-Erkrankung selbst. Es ist keine klare Diagnose möglich.«", <br/>, <br/>, "Dezember 2021: »Ich habe immer noch Wortfindungsstörungen und werde bei Kleinigkeiten oft unsicher und bekomme Panik. Die innere Unruhe bleibt. Wenn ich am Rechner sitze und etwas nicht hinbekomme, würde ich das Ding am liebsten in die Ecke schmeißen und davon rennen. Ich habe trotzdem Träume und Pläne und möchte im Sommer mit dem Auto durch Europa reisen.«"],
          "Otto Rommel war gerade auf einer Weltreise, als die Corona-Pandemie begann. Als er wieder zu Hause ankam, musste er mit trockenem Husten und einer Lungenentzündung auf die Intensivstation.",
        ],
        textNovember: "",
        name: 'Otto Rommel, 68, Magstadt',
        nameShort: 'Otto Rommel',
        audio: "/audio/OttoRommel_02Version_frei.mp3",
      },
      {
        key: 'LennartReimers',
        img: reimers01,
        images: [reimers02, reimers03, reimers01],
        text: '»Ich komme mir vor wie der Großvater, der besucht wird. Es sind eher kurze Besuche und es wird dann meistens über Gesundheit und Krankheit gesprochen.«',
        texts: [
          ["»Manchmal schaue ich für eine halbe Stunde an die Wand, weil keine Konzentration möglich ist.«", <br/>, <br/>, "Lennart Reimers steht am Großen Mühlenteich in Lensahn. Hier geht er in seiner Mittagspause spazieren, um Kraft für den restlichen Arbeitstag zu tanken."],
          "»Acht Stunden Arbeit am Tag stehe ich durch. Danach ist das Leben erst einmal vorbei. Abends und am Wochenende ist Couch angesagt. Vielleicht schaffe ich noch einen kurzen Spaziergang, das ist alles.« Durch den Bewegungsmangel ist sein Körper in einer Schonhaltung. Nach Feierabend trainiert er für wenige Minuten mit einer Faszienrolle. Die kurze Dehnung weitet seinen Brustkorb und hilft ihm, besser Luft zu bekommen. Danach geht er direkt auf die Couch.",
          "Ende März 2020 bekommt Lennart Reimers Atemnot und überlegt, den Krankenwagen zu rufen. Nach einer Stunde bessert sich sein Zustand. Zwei Wochen lang hat er Fieber, Husten und Kopfschmerzen. Dann fühlt er sich wieder fit und arbeitet bereits vom Bett aus wieder.",
        ],
        textNovember: "",
        name: 'Lennart Reimers, 27, Scharbeutz',
        nameShort: 'Lennart Reimers',
        audio: "/audio/LennartReimers_zweiteVersion.mp3",
      },
    ];
    const storiesEn = [
      {
        key: 'TheresaMakowsky',
        img: makowsky01,
        images: [makowsky03, makowsky02, makowsky01],
        text: '»Every time I washed my hair, I saw tufts disappear down the drain.«',
        texts: [
          "»I wished so many times last summer that I didn't have this disease. It was like someone was pressing on my chest with their hand to make it harder for me to breathe.«",
          "»It can't be true that an illness makes me feel as if I've run a marathon, even months later.«",
          "Theresa Makowsky contracted the disease in a Berlin club at the end of February 2020. Shortly before the first lockdown, she was already considered recovered. But long-term consequences accompanied her for more than a year: pain in the lungs, olfactory irritations, fatigue, and hair loss.",
        ],
        textNovember: "November 2021: »I am feeling much better. I still have severe hair loss, but the shortness of breath is no longer there. Maybe it is also due to the stress as I am about to take my exams. I have high hopes that I will be really fit again in six months.«",
        name: 'Theresa Makowsky, 26, Berlin',
        nameShort: 'Theresa Makowsky',
        audio: "/audio/TheresaMakowsky_02Version_frei.mp3",
      },
      {
        key: 'UteBauerVollbracht',
        img: vollbracht01,
        images: [vollbracht02, vollbracht03, vollbracht01],
        text: '»All I did was sleep. And at some point, I couldn\'t sleep either, even though I was tired.«',
        texts: [
          "Ute Bauer-Vollbracht loves working in a residential home for people with disabilities. She used to manage to look after all 12 residents simultaneously. Usually, she likes to fill in on weekends or for night duties. Today, she's happy if she can make it to work at all. Even cleaning out her dishwasher is challenging.",
          "»I no longer had the strength to go up the stairs. It felt like climbing a mountain: the legs, the arms, and the head. Nothing wants to anymore. The whole body doesn't do it anymore.«",
          "Ute Bauer-Vollbracht was diagnosed with a covered perforation in early April. She had to undergo emergency surgery on her intestine and probably contracted Corona in the hospital.After her acute illness, she got better again, but from August 2020, she became weaker every day. Headaches, shortness of breath, difficulty finding words, and aching limbs are part of her daily routine.",
        ],
        textNovember: "",
        name: 'Ute Bauer-Vollbracht, 55, Mechterstädt',
        nameShort: 'Ute Bauer-Vollbracht',
        audio: "/audio/UteBauerVollbracht_02Version_frei.mp3",
      },
      {
        key: 'KarlBaumann',
        img: baumann01,
        images: [baumann02, baumann01],
        text: '»I was permanently at full speed. I was working. The life I had before doesn\'t exist anymore..«',
        texts: [
          "Karl Baumann during an examination at the hospital in Donaustauf. His values show no abnormalities. But has numerous long-term consequences. He is forgetful and feels weak. His wife has to carry crates of drinks into the apartment today.",
          "When Karl Baumann was put into an artificial coma in March 2020, the nursing staff weren't wearing protective clothing. When he woke up, he felt as if they had shot him onto Mars. After his five-week rehab, he's still not better. Instead, Baumann looks for a support group. After his unsuccessful search, he starts a group himself for patients with long-term consequences and their relatives. »I didn't realize how sick I was until January 2021.« He had to work through the intensive care unit in psychotherapy.",
        ],
        textNovember: "November 2021: »Today, I can walk better, and the shortness of breath is gone. But I am still exhausted. So at the end of November, I'll be in rehab again. Let's see what else improves there.«",
        name: 'Karl Baumann, 53, Wenzenbach',
        nameShort: 'Karl Baumann',
        audio: "/audio/KarlBaumann_02Version_frei.mp3",
      },
      {
        key: 'LuisaMeissner',
        img: meissner01,
        images: [meissner02, meissner03, meissner01],
        text: '»People with Corona long-term effects don\'t get help. That\'s inexplicable to me.«',
        texts: [
          "Riding therapy helps Luisa Meißner work through her physical and emotional trauma. She and her mother often feel left alone. Unfortunately, many treatments are not covered by health insurance. This makes it difficult for the young woman to recover.",
          "As a result of Covid-19 and her time in intensive care, Luisa Meißner develops the symptoms of ataxia, a disorder of cerebellar function. A year after her illness, she can only walk a few meters with the help of canes. Often a little effort is enough, and she can no longer keep on her feet. When unplanned waits occur, she usually sits on the floor.",
          "Luisa Meißner did an Erasmus internship in Seville, Spain, where she became infected with the virus there. She spent 32 days in an artificial coma and could only be flown back to Germany. »At the beginning, some of the doctors estimated that I would need at least five years to get completely well again. Others rather took away my hope of that.«",
        ],
        textNovember: "November 2021: »With walking, I have made some progress. Standing still works poorly. I also still have the brain fog and forgetfulness.«",
        name: 'Luisa Meißner, 23, Düsseldorf',
        nameShort: 'Luisa Meißner',
        audio: '/audio/LuisaMeissner_DritteVersion_Frei.mp3',
      },
      {
        key: 'DanielaMueller',
        img: mueller01,
        images: [mueller02, mueller03, mueller01],
        text: '»I had trouble sleeping for over a year because I was afraid I would forget to breathe at night.«',
        texts: [
          "»It feels like getting up in the morning with a battery that's only ten percent charged.«",
          "Daniela Müller got infected with the Coronavirus a year ago. She has to fight and claim many treatments and therapies herself. »With Long Covid, I was the system jumper because I could not be assigned to a specialty. Through an adapted sports program, I regained confidence in my body.«",
          "Daniela Müller has experienced a pandemic before. She lived in Mexico when swine flu broke out there in 2009. That's why she wasn't worried at the beginning of 2020. Even before the first lockdown, she had gotten infected at work.",
        ],
        textNovember: "November 2021: »The fatigue disappeared after the first vaccination. In the meantime, I am living almost normally again. My muscles still cause problems. Even with regular exercise, I don't build up strength and often have muscle pain.«",
        name: 'Daniela Müller, 46, Stuttgart',
        nameShort: 'Daniela Müller',
        audio: "/audio/DanielaMüller03_FREI.mp3",
      },
      {
        key: 'WolfgangGerteisen',
        img: gerteisen01,
        images: [gerteisen02, gerteisen01],
        text: '»My life before Corona was full. I was never sick. I only knew doctors\' offices from hearsay.«',
        texts: [
          "It was not clear whether he would survive for a long time. After artificial respiration, he first learned to swallow and speak again. Today, he can hike 20 kilometers at a stretch, and goes swimming and mountain biking again.",
          ["Wolfgang Gerteisen was in intensive care for 87 days. In early March 2020, he got infected at a ski resort in France. »The danger was present, but I underestimated it.«", <br/>, <br/>, "Coughing, sleep disturbances, and scarring in his lungs remain. But he can once again lead a self-determined life."],
        ],
        textNovember: "November 2021: »I am currently in the process of reducing my medication. I still cough, yet I have achieved all my goals and feel well. I even ride my mountain bike up the stairs in front of my house now.«",
        name: 'Wolfgang Gerteisen, 66, Münstertal',
        nameShort: 'Wolfgang Gerteisen',
        audio: "/audio/WolfgangGerteisen_ErsteVersion_frei.mp3",
      },
      {
        key: 'BirgitBrunner',
        img: brunner01,
        images: [brunner02, brunner01],
        text: '»It\'s not going to be the same as it was before. It took me a long time to accept that.«',
        texts: [
          "She often forgets simple things, like the ingredients for lasagna. Many doctors do not take her and her daughter's symptoms seriously. Through a cry for help in Facebook groups, she has come across a neurologist in Vienna, and a pulmonologist in Germany who listens and takes her symptoms seriously.",
          "Birgit Brunner fell ill with Covid-19 and her 10-year-old daughter at the beginning of March 2020, but both still suffer from states of exhaustion, a weakened immune system, and Brain Fog today.",
        ],
        textNovember: "November 2021: »After an online breathing course, I can speak better and don't have to gasp for air so frequently anymore. But something new is constantly coming up. Right now, I am struggling with phlebitis in my legs. Sophie continues to have severe setbacks. After an hour of gym class, nothing works for now.«",
        name: 'Birgit Brunner, 51, Höchst, Vorarlberg',
        nameShort: "Birgit Brunner",
        audio: "/audio/BirgitBrunner_ErsteVersion_frei.mp3",
      },
      {
        key: 'OttoRommel',
        img: rommel01,
        images: [rommel02, rommel03, rommel01],
        text: '»I never thought I was on the verge of organ failure.«',
        texts: [
          "Otto Rommel is in a practice for integrative medicine and fascia therapy. He has many traumatic memories from his time in the intensive care unit and suffers from sleep disorders and panic attacks.",
          ["Otto Rommel goes for a walk with his dog, Pyron. Together with other Long Covid sufferers, he founded an advocacy group:", <br/>, <br/>, "»I think the problems of Long Covid are even harder for society to understand than the corona disease itself. There is no clear diagnosis possible.«"],
          "I still have word-finding problems and often become unsteady about small things and panic. The inner turmoil remains. If I'm sitting at the computer and can't get something right, I'd like to throw the thing in the corner and run away. Nevertheless, I have dreams and plans and would like to travel through Europe by car in the summer.",
        ],
        textNovember: "",
        name: 'Otto Rommel, 68, Magstadt',
        nameShort: 'Otto Rommel',
        audio: "/audio/OttoRommel_02Version_frei.mp3",
      },
      {
        key: 'LennartReimers',
        img: reimers01,
        images: [reimers02, reimers03, reimers01],
        text: '»I feel like the grandfather who gets visited. They tend to be short visits, and then they mostly talk about health and illness.«',
        texts: [
          ["»Sometimes, I look at the wall for half an hour because no concentration is possible.«", <br/>, <br/>, "Lennart Reimers stands by the Great Mill Pond in Lensahn where he goes for a walk here during his lunch break. This recharges his batteries for the rest of the workday."],
          "»I can get through eight hours of work a day. After that, life is over for the time being. In the evenings and on weekends, it's couch time. Maybe I'll manage a short walk, that's all.« After work, he stretches for a few minutes on a fascial roller. After that, he goes straight to the couch. Due to the lack of exercise, he got a poor posture. The short stretch expands his chest and helps him breathe better.",
          "At the end of March 2020, he gets short of breath and considers calling the ambulance. After an hour, his condition improves, and he has had a fever, cough, and headache for about two weeks. Then he feels fit again and is already working out of bed. When he wants to do sports again, he gets severe setbacks. Today, he is already happy if he manages a short walk on the weekend.",
        ],
        textNovember: "",
        name: 'Lennart Reimers, 27, Scharbeutz',
        nameShort: 'Lennart Reimers',
        audio: "/audio/LennartReimers_zweiteVersion.mp3",
      },
    ];

    const stories = this.state.language === 'EN' ? storiesEn : storiesDe;

    return stories.sort((a,b) => Math.floor(Math.random() * 4) - Math.floor(Math.random() * 4));
  }

  toggleLanguage = () => {
    this.setState(({language, currentStory}) => ({
        language: language === 'DE' ? 'EN' : 'DE',
      }),
      () =>
        this.setState(({currentStory}) =>
          ({
            stories: this.stories,
            currentStory: Object.values(this.stories || {}).find(story => story.key === currentStory?.key) || null,
          })
        )
    )
  }

  render(){

    const {informationVisible, imprintVisible, expertVisible, closePageButtonVisible, glassVisible, stories, currentStory, language} = this.state;
    const positions = this.positions;

    return(
      <React.Fragment>

        <CookieConsent
          location="bottom"
          buttonText="Einverständnis erteilen"
          declineButtonText="Cookies ablehnen"
          enableDeclineButton
          style={{ background: color2, color: "black" }}
          buttonStyle={{ color: "#000", fontSize: "18px", backgroundColor: "#fff", height: "100%" }}
          declineButtonStyle={{ color: "#000", fontSize: "18px", backgroundColor: "#fff", height: "100%" }}
          onAccept={() => { this.onCookiesAccepted()}}
          onDecline={() => { this.onCookiesDeclined()}}
        >
          Diese Website nutzt technisch notwendige Cookies und Cookies zur Erhebung anonymisierter Nutzungsstatistiken. Bitte lesen Sie dazu unsere <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => this.toggleImprint()}>Datenschutzerklärung</span>.
        </CookieConsent>

        { glassVisible && <Glass onClick={() => this.onClosePageButton()} lang={language} showLogo={currentStory === null && !informationVisible && !imprintVisible && !expertVisible} /> }

        {stories &&
          <Menu
            ref={c => this.menuRef = c}
            onInformation={() => this.toggleInformation()}
            onImprint={() => this.toggleImprint()}
            onPersonen={() => this.onClosePageButton()}
            onPerson={(divId, story) => { this.centerOn(divId); this.toggleStory(story);} }
            onExpert={() => this.toggleExpert()}
            stories={stories}
            positions={positions}
            toggleLanguage={this.toggleLanguage}
          />
        }

        {
          closePageButtonVisible && (window.innerWidth > 1000 || (window.innerWidth < 1000 && !currentStory)) &&
          <ClosePageButton style={{backgroundColor: imprintVisible ? color4 : color2}} onClick={() => this.onClosePageButton()}><ClosePageButtonText>Close &#x2715;</ClosePageButtonText></ClosePageButton>
        }

        { informationVisible && <InformationPage lang={language} /> }

        { imprintVisible && <Imprint /> }

        { expertVisible && <Experts /> }

        { currentStory && <FullScreenStory story={currentStory} closeFunction={() => this.onClosePageButton()} />}

        <PageContainer id="page-container">

          <ZoomContainer id="zoom-container" onClick={() => this.onClosePageButton()}>

            {
              stories && this.positions.map((pos,idx) => {
                const story = stories[idx];
                return <Story key={story.key} position={pos} data={story} onClick={(event) => this.toggleStory(story, event)} />;
              })
            }

          </ZoomContainer>
        </PageContainer>
      </React.Fragment>
    )
  }
}