import React from "react";
import styled, {css} from "styled-components";
import {color5, PageText} from "./Components";

const ImprintContainer = styled.div`
  background-color: ${color5};
  position: absolute;
  top: 0;
  left: ${window.innerWidth > 1000 ? "600px" : "0px"};
  flex: 1;
  height: 100%;
  z-Index: 100;
  overflow: scroll;
  width: ${window.innerWidth > 1000 ? window.innerWidth - 600 + "px" : "100%"};
`;

export class Experts extends React.PureComponent {
  constructor(){
    super();
  }

  render() {

    return (
      <ImprintContainer>
        <PageText>
          <div style={{flex: 1, padding: 10, paddingTop: 30, minWidth: "50%"}}>
            Hier werden in den nächsten Wochen Interviews mit Expert:innen zum Thema Long Covid veröffentlicht.
            <br/><br/>
            Alle Updates und Hintergründe zum Projekt werden auf dem Instagram Kanal von Patrick Junker <a href="https://www.instagram.com/patrick__junker/">@patrick__junker</a> veröffentlicht.
          </div>

          <div style={{flex: 1, padding: 10, paddingTop: 30, minWidth: "50%", wordBreak: "break-word"}}>
          </div>
        </PageText>
      </ImprintContainer>
    );
  }
}