import styled, {css} from "styled-components";

export const color1 = "#000000";
export const color2 = "#E6FFFE";
export const color3 = "#FFE0EF";
export const color4 = "#EEDEFF";
export const color5 = "#EAEAEA";

export const MenuContainer = styled.div`
  position: fixed; 
  top:0; 
  left:0; 
  display: flex; 
  flex-direction: row; 
  width: 100%; 
  z-index: 200;
  flex-wrap: wrap;
`;

export const MenuItem = styled.div`
  width: 200px;
  max-width: 200px;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const MenuItemText = styled.div`
  font-size: ${window.innerWidth > 1000 ? "25px" : "18px"};
  color: ${color1};
  padding-left: 10px;
  font-family: Matter, Roboto, Arial, sans-serif;
`;

export const PersonMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  height: ${window.innerWidth > 1000 ? "40px" : "27px"};  
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid black;
`;

export const MobileMenuContainer = styled.div`
  position: fixed; 
  top:0; 
  left:0; 
  height: 54px; 
  display: flex; 
  flex-direction: column; 
  width: 100%; 
  z-index: 200;
`;

export const MobileMenuBottomContainer = styled.div`
  position: fixed; 
  bottom:0; 
  left:0; 
  height: 27px; 
  display: flex; 
  flex-direction: row; 
  width: 100%; 
  z-index: 200;
`;

export const MobileMenuContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MobileMenuItem = styled.div`
  width: 50%;
  align-items: center;
  display: flex;
  cursor: pointer;
  height: 27px;  
`;


export const ClosePageButton = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: ${window.innerWidth > 1000 ? "200px" : "50%"};
  max-width: 200px;
  height: ${window.innerWidth > 1000 ? "40px" : "27px"};  
  z-Index: 200;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
`;

export const ClosePageButtonText = styled.div`
  font-size: ${window.innerWidth > 1000 ? "25px" : "18px"};
  color: ${color1};
`;

export const PageText = styled.div`
  font-size: ${window.innerWidth > 1000 ? "25px" : "20px"};
  color: ${color1};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 80px;
  padding-top: ${window.innerWidth > 1000 ? "40px" : "120px"};
`;











export const ImageContainer = styled.div`
  position: fixed;
  width: 100%; 
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

export const Image = styled.img`
  ${props => props.imagePosition && props.orientation === "portrait" ? css`height: 100%;` : css`width: 100%`};
  ${props => props.imagePosition === "left" && props.orientation === "portrait" ? css`position:absolute;left:0px;` : null};
  ${props => props.imagePosition === "right" && props.orientation === "portrait" ? css`position:absolute;right:0px;` : null};
`;

export const PageContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    outline: none;
`;

export const ZoomContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 3200px;
    height: 3000px;
`;