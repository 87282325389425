import React, {Component} from "react";
import styled from "styled-components";
import ReactAudioPlayer from "react-audio-player";
import {color1, color2, color3, color4} from "./Components";

const StoryContainer = styled.div`
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 100;
`;

const MobileStoryContainer = styled.div`
  outline: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 54px;
  left: 0;
  z-index: 100;
`;

const TextAudioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 350px;
  flex-grow: 1;
`;

const Name = styled.div`
  padding: 20px;
  height: 40px;
  background-color: ${color3};
  display: flex;
  align-items: center;
`;

const AudioContainer = styled.div`
  padding: 20px;
  background-color: ${color3};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileAudioContainer = styled.div`
  padding: 20px;
  background-color: ${color3};
  display: flex;
  flex-direction: column;
  align-items: center;

`;

const TextContainer = styled.div`
  padding: 20px;
  padding-bottom: 80px;
  min-height: 250px;
  background-color: ${color4};
  display: flex;
  flex-direction: column;
`;

const MobileTextContainer = styled.div`
  padding: 20px;
  padding-bottom: 80px;
  min-height: 250px;
  background-color: ${color3};
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  background-color: ${color3};
`;


export const NextButton = styled.div`
  width: 60px;
  height: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const NextButtonText = styled.div`
  font-size: 25px;
  color: ${color1};
`;

export class FullScreenStory extends Component {
  constructor() {
    super();
    this.state = {
      currentPos: 0,
    }
  }

  goNext = () => {
    const maxPos = this.props.story.images.length;
    const nextPos = this.state.currentPos + 1 === maxPos ? 0 : this.state.currentPos + 1;
    this.setState({currentPos: nextPos});
  }

  goPrev = () => {
    const maxPos = this.props.story.images.length;
    const nextPos = this.state.currentPos === 0 ? maxPos - 1 : this.state.currentPos - 1;
    this.setState({currentPos: nextPos});
  }

  render() {
    const {closeFunction} = this.props;
    const {images, texts, textNovember, nameShort, audio} = this.props.story;
    const {currentPos} = this.state;
    const playerStyle = {outline: "none", width: "100%"};
    const imageStyle = {height: window.innerHeight - 40, cursor: "pointer"};
    const imageMobileStyle = {width: "100%", cursor: "pointer"};

    return (
      <>
        {
          window.innerWidth > 1000 ?
            <StoryContainer>
              <img src={images[currentPos]} style={imageStyle} onClick={this.goNext}/>
              <TextAudioContainer>
                <Name>{nameShort}</Name>
                <AudioContainer>
                  {audio &&
                  <>
                    <ReactAudioPlayer
                      src={window.location.origin + audio}
                      style={playerStyle}
                      controls
                      controlsList="nodownload"
                    />
                    <div style={{fontSize: 12, marginTop: 20}}>Interview vom Februar 2021</div>
                  </>
                  }
                </AudioContainer>
                <ButtonContainer>
                  <NextButton onClick={this.goPrev}><NextButtonText>&larr;</NextButtonText></NextButton>
                  <div style={{display: "flex", flex: 1, fontSize: 12, alignItems: "center", justifyContent: "center", flexDirection: "column"}}><div>{`Foto ${currentPos+1}/${images.length}`}</div></div>
                  <NextButton onClick={this.goNext}><NextButtonText>&rarr;</NextButtonText></NextButton>
                </ButtonContainer>
                <TextContainer>
                  <div>{texts[currentPos]}</div>
                  {currentPos + 1 === images.length - 1 && <div><br/>{textNovember}</div>}
                </TextContainer>
                <TextAudioContainer onClick={closeFunction} />
              </TextAudioContainer>
            </StoryContainer>
            :
            <MobileStoryContainer>
              <img src={images[currentPos]} style={imageMobileStyle} onClick={this.goNext}/>
              <ButtonContainer>
                <NextButton onClick={this.goPrev}><NextButtonText>&larr;</NextButtonText></NextButton>
                <Name style={{flex: 1, flexDirection: "row", justifyContent: "center"}}>{nameShort}</Name>
                <NextButton onClick={this.goNext}><NextButtonText>&rarr;</NextButtonText></NextButton>
              </ButtonContainer>
              <MobileAudioContainer>
                {audio &&
                <>
                  <ReactAudioPlayer
                    src={window.location.origin + audio}
                    style={playerStyle}
                    controls
                    controlsList="nodownload"
                  />
                  <div style={{fontSize: 12, marginTop: 20}}>Interview vom Februar 2021</div>
                </>
                }
              </MobileAudioContainer>
              <MobileTextContainer>
                <div>{texts[currentPos]}</div>
                {currentPos + 1 === images.length && <div><br/>{textNovember}</div>}
              </MobileTextContainer>
            </MobileStoryContainer>
        }
      </>
    )
  }
}