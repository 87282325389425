import './App.css';
import React, { useEffect, useState } from "react";
import {LongCovid} from "./components/LongCovid";

function App() {
  return (
    <LongCovid />
  );
}

export default App;
