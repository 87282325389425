import React from "react";
import styled from "styled-components";
import longCovidLogo from "../images/Logos/Long_Covid_Logo-01.svg";

const GlassContainer = styled.div`
  outline: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
  backdrop-filter: blur(55px);
  ${navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? "background-color: white;opacity: 0.9;" : ""}
`;

export class Glass extends React.PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.forceUpdate();
  }

  render() {
    const {showLogo, lang} = this.props;

    return (
      <GlassContainer onClick={this.props.onClick}>
        {showLogo &&
          <div style={{
            textAlign: "center",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: window.innerWidth > 1000 ? "10%" : "20px"
          }}>
            <img src={longCovidLogo} width={window.innerWidth > 1000 ? 400 : "80%"}/>
            {
              lang === 'DE' ?
                <>
                  <div style={{fontSize: window.innerWidth > 1000 ? 40 : 28, marginTop: 40, marginBottom: 20}}>
                    Das Leben von früher gibt es nicht mehr.
                  </div>
                  <div style={{fontSize: window.innerWidth > 1000 ? 25 : 18}}>
                    Der Fotojournalist Patrick Junker porträtiert Menschen, die seit mehr als einem Jahr unter
                    Langzeitfolgen ihrer Covid-19-Infektion leiden.
                  </div>
                </>
                :
                <>
                  <div style={{fontSize: window.innerWidth > 1000 ? 40 : 28, marginTop: 40, marginBottom: 20}}>
                    The life of the past no longer exists.
                  </div>
                  <div style={{fontSize: window.innerWidth > 1000 ? 25 : 18}}>
                    Photojournalist Patrick Junker portrays people who have suffered long-term effects of their Covid-19
                    infection for more than a year.
                  </div>
                </>
            }
          </div>
        }
      </GlassContainer>
    );
  }
}