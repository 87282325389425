import React from "react";
import styled from "styled-components";
import {color1, color2, color3} from "./Components";

const StoryContainer = styled.div`
  outline: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 470px;
  background-color: ${color3};
  cursor: pointer;
`;

export const NextButton = styled.div`
  width: 200px;
  max-width: 200px;
  height: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: ${color2};
  padding-left: 20px;
`;

export const NextButtonText = styled.div`
  font-size: 25px;
  color: ${color1};
`;

export class Story extends React.PureComponent {
  constructor() {
    super();
  }

  render(){
   const {key, img, text, name} = this.props.data;
   const {top, left} = this.props.position;
   const {onClick} = this.props;

    return (
      <StoryContainer id={key} style={{top, left}} onClick={window.innerWidth > 1000 ? onClick : f=>f}>
        <img src={img} width={470} height={626} />
        <div style={{padding: 20}}>
          <div>{text}</div>
          <div style={{fontFamily: "MatterSemiBold, Roboto, Arial, sans-serif"}}>{name}</div>
        </div>
        <div style={{height: 40, display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
          <NextButton onClick={onClick} onTouchEnd={onClick}>
            <NextButtonText>Mehr →</NextButtonText>
          </NextButton>
        </div>
      </StoryContainer>
    )
  }
}