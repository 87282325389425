import React from "react";
import styled from "styled-components";
import {PageText, color1, color4} from "./Components";
import PatrickJunker from "../images/patrick-junker.jpg";


const InformationPageContainer = styled.div`
  background-color: ${color4};
  position: absolute;
  top: 0px;
  left: ${window.innerWidth > 1000 ? "200px" : "0px"};
  flex: 1;
  height: 100%;
  z-Index: 100;
  overflow: scroll;
`;

export class InformationPage extends React.PureComponent {
  constructor(){
    super();
  }

  render() {
    const {lang} = this.props;
    return (
      <InformationPageContainer>
        <PageText>
          {
            lang === 'DE' ?
              <>
                <div style={{flex: 1, padding: 10, paddingTop: 30}}>
                  DAS PROJEKT
                  <br/><br/>Mittlerweile leben wir seit fast zwei Jahren mit der Corona-Pandemie. Gewissermaßen leidet die ganze Gesellschaft an den Langzeitfolgen dieser Krise. Laut Robert-Koch-Institut haben sich bisher 7.066.412 Menschen nachweislich infiziert (Stand: 29. Dezember 2021).
                  <br/><br/>Bei manchen der Genesenen bleiben auch Monate nach ihrer Covid-19-Infektion Beschwerden, die ihre Lebensqualität stark beeinträchtigen. Die ersten Studien gehen davon aus, dass 10 bis 15 Prozent der Infizierten auch nach der akuten Krankheitsphase symptomatisch bleiben. Bei manchen verschwinden die Beschwerden in den ersten drei Monaten wie von selbst. Andere Betroffene leiden schon über ein Jahr an Langzeitfolgen: chronische Erschöpfung, Vergesslichkeit, Schmerzen, Geruchsverlust und ein ständiges Krankheitsgefühl sind einige davon. Dabei sind Symptome wie die Fatigue nach Viruserkrankungen gut dokumentiert. Sie können im schlimmsten Fall als post-infektiöses Chronischen Fatigue-Syndrom (ME/CFS) auftreten. Die Myalgische Enzephalomyelitis / das Chronische Fatigue-Syndrom ist seit 1969 von der Weltgesundheitsorganisation anerkannt, gehört allerdings zu den letzten großen Krankheiten, die kaum erforscht sind. Die Krankheitsbilder des Post-Covid-Syndrom und ME/CFS haben zahlreiche Überschneidungen. Beide Krankheiten haben eine mangelhafte Versorgungslage.
                  <br/><br/>Was Expertinnen und Experten heute wissen: Long Covid ist eine Multiorgan-Krankheit. Doch die Möglichkeit einer eindeutigen Diagnose oder eine vielversprechende Therapie fehlen.
                  <br/><br/>In diesem Multimedia-Projekt erzählen Menschen von ihrem Alltag, die seit über einem Jahr an den Folgen ihrer Covid-19-Infektion leiden. Die ersten Interviews für das Projekt wurden bereits im Februar 2021 geführt. Das Projekt soll ständig erweitert werden. Expertinnen und Experten ordnen die komplexen Spätfolgen ein und versuchen, das Krankheitsbild besser verständlich zu machen.
                  <br/><br/>Für weitere Informationen für Betroffene und deren Angehörigen möchten wir hier auf die S1-Leitlinie Post-COVID/Long-COVID und die Seite von Long COVID Deutschland verlinken. Ärztinnen und Ärzte und zum Teil selbst Betroffene haben die S1-Leitlinie Post-COVID/Long-COVID erstellt. Long COVID Deutschland ist eine Bundesweite Initiative für die Belange von Long-COVID-Betroffenen.
                  <br/><br/><b>Auszüge aus der S1-Leitlinie Post-COVID/Long-COVID für Betroffene:</b>
                  <br/><br/>Allen Studien gemeinsam ist: Es scheint ein nennenswerter Anteil der COVID-19-Patienten betroffen zu sein. Die Erkrankungen mit dem neuartigen Coronavirus, SARS-CoV-2, werden als COVID- 19 bezeichnet. Hiervon ist insbesondere die Lunge betroffen, doch können auch andere Organe befallen sein. Bei ungefähr zehn Prozent der Erkrankten halten die Beschwerden länger als vier Wochen an. Dieser Zustand wird als “Long-COVID” bzw. “Post-COVID” bezeichnet.
                  <br/><br/>“Long-COVID”: Beschwerden, die länger als vier Wochen nach Infektion auftreten oder fortbestehen.
                  <br/><br/>“Post-COVID”: Beschwerden, die länger als zwölf Wochen nach Infektion auftreten oder fortbestehen.
                  <br/><br/>Link zur Leitlinie für Betroffene und zur Langfassung der Leitlinie: <a href="https://www.awmf.org/leitlinien/detail/ll/020-027.html">www.awmf.org/leitlinien/detail/ll/020-027.html</a>
                  <br/><br/>Viele Informationen zu Ambulanzen, Selbsthilfegruppen, Forschungsvorhaben, aktuelle Medienberichte und mehr gibt es unter: <a href="https://longcoviddeutschland.org">longcoviddeutschland.org</a>
                  <br/><br/>Alle Angaben zu Häufigkeiten sind noch mit erheblichen Unsicherheiten verbunden. In einer Studie vom September 2021 wurden die Gesundheitsdaten von 273 618 Patient:Innen ausgewertet und kamen zu dem Schluss, dass bei einem von drei Patienten zwischen drei und sechs Monaten nach Erkrankung mit Covid-19 ein oder mehrere Merkmale von Long Covid festgestellt wurden.
                  <br/><br/>Link zur Studie: <a href="https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1003773">https://journals.plos.org/plosmedicine/ article?id=10.1371/journal.pmed.1003773</a>
                </div>
                <div style={{flex: 1, padding: 10, paddingTop: 30}}>
                  DER FOTOGRAF - Patrick Junker
                  <br/><br/>
                  <img src={PatrickJunker} style={{maxWidth: "90%"}} />
                  <br/>Foto: Victor Hedwig
                  <br/><br/>Bereits seit März 2020 dokumentiert der Fotojournalist Patrick Junker die Corona-Pandemie. Seine Arbeit »There is glory in prevention« über die erste Welle wurde im öffentlichen Raum ausgestellt und in lokalen wie überregionalen Print- und Onlinemedien besprochen:
                  <br/><br/><a href="https://www.thereisgloryinprevention.de">www.thereisgloryinprevention.de</a>
                  <br/><br/>Patrick Junker (Jahrgang 1991) ist freier Fotojournalist in Dortmund. Patrick Junker arbeitet für Redaktionen wie stern, Brand Eins, Capital, CNN International, DER SPIEGEL, DIE ZEIT, fluter online, Frankfurter Allgemeine Zeitung, GEO Wissen, Handelsblatt.
                  <br/><br/>Seine Arbeit »There is glory in prevention« wurde vom Ministerium für Wissenschaft, Forschung und Kunst Baden-Württemberg gefördert. Seine Online- und Printreportage zum Thema Organspende wurde auf internationalen Festivals ausgestellt und unter anderem mit dem Grimme Online Award, dem Hansel-Mieth-Preis und dem Preis für Wissenschaftsfotografie der Deutschen Gesellschaft für Photographie ausgezeichnet. Mit seinen Langzeitreportagen möchte er Verständnis für sensible Themen schaffen.
                  <br/><br/>Die Recherche zu »Long Covid« wird von der Riff freie Medien gGmbH aus Mitteln der Klaus Tschira Stiftung gefördert.
                  <br/><br/>Alle Updates und Hintergründe zum Projekt werden auf dem Instagram-Kanal von Patrick Junker <a href="https://www.instagram.com/patrick__junker/">@patrick__junker</a> veröffentlicht.
                </div>
              </>
              :
              <>
                <div style={{flex: 1, padding: 10, paddingTop: 30}}>
                  THE PROJECT
                  <br/><br/>We have now been living with the Corona pandemic for two years.
                  <br/><br/>For some recovered from Covid-19, symptoms remain that severely impact their quality of life. Approximately 10-20% of those infected with Covid-19 remain symptomatic months after the acute disease phase. Some suffer long-term consequences for more than a year: chronic fatigue, forgetfulness, pain, loss of smell, and a constant feeling of illness, to name a few. What experts know today: Long Covid is a multi-organ disease. But the possibility of a precise diagnosis or a promising therapy is missing. This is a project about people who suffer from long-term consequences of their infection since the first wave of the pandemic.
                  <br/><br/>Yet symptoms such as fatigue following viral diseases are well documented. They can present as post-infectious chronic fatigue syndrome (ME/CFS) in the worst cases. Myalgic encephalomyelitis/chronic fatigue syndrome has been recognized by the World Health Organization since 1969 but is one of the last major diseases to be poorly studied. The clinical pictures of Post-Covid syndrome and ME/CFS have numerous overlaps. Both diseases have a secondary care situation.
                  <br/><br/>In this multimedia project, people talk about their everyday lives who have been suffering from the consequences of their Covid 19 infection for more than a year. The first interviews for the project were already conducted in February 2021. The project is ongoing and expected to expand.
                  <br/><br/>All data on frequencies are still subject to considerable uncertainty. In a September 2021 study, health data from 273.618 patients were analyzed and concluded that one in three patients were found to have one or more features of Long Covid between three and six months after becoming ill with Covid-19.
                  <br/><br/>Link to the study: <a href="https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1003773">https://journals.plos.org/plosmedicine/ article?id=10.1371/journal.pmed.1003773</a>
                </div>
                <div style={{flex: 1, padding: 10, paddingTop: 30}}>
                  THE PHOTOGRAPHER - Patrick Junker
                  <br/><br/>
                  <img src={PatrickJunker} style={{maxWidth: "90%"}} />
                  <br/>Photo: Victor Hedwig
                  <br/><br/>Photojournalist Patrick Junker has been documenting the Corona pandemic since as early as March 2020. His work "There is glory in prevention" about the first wave has been exhibited in public spaces and reviewed in local and national print, as well as online media:
                  <br/><br/><a href="https://www.thereisgloryinprevention.de">www.thereisgloryinprevention.de</a>
                  <br/><br/>Patrick Junker (born 1991) is a freelance photojournalist in Dortmund. Patrick Junker works for editorial offices such as stern, Brand Eins, Capital, CNN International, DER SPIEGEL, DIE ZEIT, fluter online, Frankfurter Allgemeine Zeitung, GEO Wissen, Handelsblatt.
                  <br/><br/>His work »There is glory in prevention« was funded by the Ministry of Science, Research and the Arts Baden-Württemberg. His online and print reportage on organ donation has been exhibited at international festivals and has received, among others, the Grimme Online Award, the Hansel-Mieth Prize, and the Prize for Science Photography of the German Photographic Society. With his long-term reportages, he wants to create understanding for sensitive topics.
                  <br/><br/>The research for »Long Covid« is supported by Riff freie Medien gGmbH with funds from the Klaus Tschira Foundation.
                  <br/><br/>All updates and backgrounds on the project will be published on Patrick Junker's Instagram channel <a href="https://www.instagram.com/patrick__junker/">@patrick__junker</a> .
                </div>
              </>
          }
        </PageText>
      </InformationPageContainer>
    );
  }
}