import React from "react";
import {
  color2, color3, color4, color5,
  MenuContainer, MenuItem, MenuItemText, PersonMenuItem,
  MobileMenuContainer, MobileMenuContainerRow, MobileMenuItem, MobileMenuBottomContainer,
} from "./Components";

export class Menu extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      listVisible: false
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.forceUpdate();
  }

  toggleListVisible(){
    this.setState({listVisible: !this.state.listVisible});
  }

  hideList(){
    this.setState({listVisible: false});
  }

  render(){

    const {listVisible} = this.state;
    const {stories, positions, onPerson, onPersonen, onInformation, onImprint, onExpert, toggleLanguage} = this.props;

    return (
      window.innerWidth > 1000 ?
        <MenuContainer>
          <MenuItem style={{backgroundColor: color2, height: 40, maxHeight: 40}} onClick={() => window.location.reload()}><MenuItemText>LONG COVID</MenuItemText></MenuItem>
          <MenuItem style={{backgroundColor: color4, height: 40, maxHeight: 40}} onClick={onInformation}><MenuItemText>INFORMATION</MenuItemText></MenuItem>
          <MenuItem style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", backgroundColor: color3}}>
            <MenuItemText onClick={() => {onPersonen(); this.toggleListVisible();}} style={{height: 40, width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>BETROFFENE</MenuItemText>
            {listVisible &&
              <div style={{
                backgroundColor: color3,
                display: "flex",
                flexDirection: "column",
                width: window.innerWidth > 1000 ? window.innerWidth - 400 : window.innerWidth
              }}>
                {
                  positions.map((pos, idx) => {
                    const story = stories[idx];
                    return <PersonMenuItem key={story.key} onClick={() => onPerson(story.key, story)}>
                      <MenuItemText>{window.innerWidth > 1000 ? story.name : story.nameShort}</MenuItemText>
                      <div style={{flexGrow: 1}}/>
                      <div style={{backgroundColor: color4, height: 40, maxHeight: 40, width: 200, maxWidth: 200, display: "flex", alignItems: "center", justifyContent: "flex-end", borderBottom: "1px solid black"}}>
                        <MenuItemText style={{paddingRight: 20}}>Mehr &rarr;</MenuItemText>
                      </div>
                    </PersonMenuItem>;
                  })
                }
              </div>
            }
          </MenuItem>
          <MenuItem style={{backgroundColor: color5, height: 40, maxHeight: 40}} onClick={onExpert}><MenuItemText>EXPERT:INNEN</MenuItemText></MenuItem>
          <div style={{flexGrow: 1}}></div>
          <MenuItem style={{backgroundColor: color3, height: 40, maxHeight: 40}} onClick={toggleLanguage}><MenuItemText>DEU - ENG</MenuItemText></MenuItem>
          <MenuItem style={{backgroundColor: color2, height: 40, maxHeight: 40}} onClick={onImprint}><MenuItemText>IMPRESSUM</MenuItemText></MenuItem>
        </MenuContainer>
        :
        <>
          <MobileMenuContainer>
            <MobileMenuContainerRow>
              <MobileMenuItem style={{backgroundColor: color2}} onClick={() => window.location.reload()}><MenuItemText>LONG COVID</MenuItemText></MobileMenuItem>
              <MobileMenuItem style={{backgroundColor: color4}} onClick={onInformation}><MenuItemText>INFORMATION</MenuItemText></MobileMenuItem>
            </MobileMenuContainerRow>
            <MobileMenuContainerRow>
              <MobileMenuItem style={{backgroundColor: color3}} onClick={() => {onPersonen();this.toggleListVisible();}}><MenuItemText>BETROFFENE</MenuItemText></MobileMenuItem>
              <MobileMenuItem style={{backgroundColor: color5}} onClick={onExpert}><MenuItemText>EXPERT:INNEN</MenuItemText></MobileMenuItem>
            </MobileMenuContainerRow>
            {
              listVisible &&
              positions.map((pos, idx) => {
                const story = stories[idx];
                return <MobileMenuContainerRow>
                  <PersonMenuItem key={story.key} onClick={() => onPerson(story.key, story)} style={{width: "100%", flexGrow: 1, backgroundColor: color3}}>
                    <MenuItemText>{story.nameShort}</MenuItemText>
                    <div style={{flexGrow: 1}}/>
                    <MenuItemText style={{paddingRight: 20}}>Mehr &rarr;</MenuItemText>
                  </PersonMenuItem>
                </MobileMenuContainerRow>;
              })
            }
          </MobileMenuContainer>
          <MobileMenuBottomContainer>
            <MobileMenuItem style={{backgroundColor: color3}} onClick={toggleLanguage}><MenuItemText>DEU - ENG</MenuItemText></MobileMenuItem>
            <MobileMenuItem style={{backgroundColor: color2}} onClick={onImprint}><MenuItemText>IMPRESSUM</MenuItemText></MobileMenuItem>
          </MobileMenuBottomContainer>
        </>
    )
  }

}