import React from "react";
import styled, {css} from "styled-components";
import {color1, color2, PageText} from "./Components";
import Logo1 from "../images/Logos/BLOMST_Logo_green.png";
import Logo2 from "../images/Logos/1_STUTTGART_mit_Pferd.png";
import Logo3 from "../images/Logos/RIF-Logo-Frei-Medien_4c.png";

const ImprintContainer = styled.div`
  background-color: ${color2};
  position: absolute;
  top: 0;
  left: 0;
  flex: 1;
  height: 100%;
  z-Index: 100;
  overflow: scroll;
`;

export class Imprint extends React.PureComponent {
  constructor(){
    super();
    this.state = {
      consent: false
    }
  }

  toggleConsent(){
    const newConsent = !this.state.consent;
    this.setState({consent: newConsent});
    window.localStorage.setItem("consent", newConsent ? "true" : "false");
  }

  componentDidMount(){
    const consent = window.localStorage.getItem("consent");
    this.setState({consent: consent === "true" ? true : false});
  }

  render() {
    const {consent} = this.state;

    return (
      <ImprintContainer>
        <PageText>
          <div style={{flex: 1, padding: 30, minWidth: 300}}>
            <div><b>IMPRESSUM</b></div>
            <br/>
            <div><b>HERAUSGEBER</b></div>
            <br/>
            <div><img src={Logo1} width={100} style={{width: 100}} /></div>
            <br/>
            Patrick Junker & Nina Kurzeja<br/>
            BLOMST!gUG<br/>
            Friedrich-Zundel-Straße 40<br/>
            70619 Stuttgart<br/>
            Geschäftsführung: Nina Kurzeja<br/>
            Telefon +49-711-9075730<br/>
            E-Mail: info@blomst.art<br/>
            Amtsgericht Stuttgart (HRB 766074)<br/><br/>
            <div><b>FOTOGRAFIE, RECHERCHE, INTERVIEWS & TEXT</b></div>
            <br/>Patrick Junker Fotojournalismus
            <br/>E-Mail: info@patrick-junker.com
            <br/>Web: patrick-junker.com
            <br/>Instagram: <a href="https://www.instagram.com/patrick__junker/">@patrick__junker</a>
            <br/><br/>
            <div><b>REDAKTIONELLE MITARBEIT</b></div>
            <br/>Isabell Stettin / Zeitenspiegel Reportagen
            <br/>www.zeitenspiegel.de
            <br/><br/>
            <div><b>GRAFIKDESIGN</b></div>
            <br/>Bareis+Nicolaus / Studio für Visuelle Kommunikation
            <br/>info@bareis-nicolaus.com
            <br/><br/>
            <div><b>WEBDEVELOPMENT</b></div>
            <br/>Adam Dreessen / Dreessen IT-Consulting GmbH
            <br/>ad@dreessen-it.com
            <br/><br/>
            <div><b>FÖRDERER</b></div>
            <div><img src={Logo2} width={300} style={{width: 300, paddingTop: 50, paddingBottom: 50}} /></div>
            <div><img src={Logo3} width={300} style={{width: 300}} /></div>
          </div>

          <div style={{flex: 1, padding: 30, wordBreak: "break-word"}}>
            <b>DATENSCHUTZERKLÄRUNG</b>
            <br/>
            <br/>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO),
            ist:<br/>
            <br/>BLOMST!gUG
            <br/>Friedrich-Zundel-Straße 40
            <br/>70619 Stuttgart<br/>
            <h2>Ihre Betroffenenrechte</h2>
            <br/>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte
            ausüben:<br/>
            <br/>
            <br/>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),<br/>
            <br/>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),<br/>
            <br/>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),<br/>
            <br/>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht
            löschen dürfen (Art. 18 DSGVO),
            <br/>
            <br/>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und<br/>
            <br/>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit
            uns abgeschlossen haben (Art. 20 DSGVO).
            <br/>
            <br/>
            <br/>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft
            widerrufen.<br/>
            <br/>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige
            Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle
            zuständige Behörde.<br/>
            <br/>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank"
            rel="nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
            <br/>
            <br/><br/><h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
            <h3>Art und Zweck der Verarbeitung:</h3>
            <br/>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig
            Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
            (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen
            Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. <br/>
            <br/>Sie werden insbesondere zu folgenden Zwecken verarbeitet:<br/>
            <br/>
            <br/>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,<br/>
            <br/>Sicherstellung einer reibungslosen Nutzung unserer Website,<br/>
            <br/>Auswertung der Systemsicherheit und -stabilität sowie<br/>
            <br/>zur Optimierung unserer Website.<br/>
            <br/>
            <br/>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art
            werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die
            dahinterstehende Technik zu optimieren. <br/>
            <h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
            <br/>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an
            der Verbesserung der Stabilität und Funktionalität unserer Website.<br/>
            <h3>Empfänger:</h3>
            <br/>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer
            Webseite als Auftragsverarbeiter tätig werden.<br/>
            <br/><br/><h3>Speicherdauer:</h3>
            <br/>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist
            für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige
            Sitzung beendet ist. <br/>
            <br/> Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine
            darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer
            anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.<br/>
            <br/><br/><h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <br/>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich
            vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
            gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus
            diesem Grund ist ein Widerspruch ausgeschlossen. <br/>
            <br/><br/><h2>Cookies</h2>
            <br/>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies handelt es sich um
            kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie
            unsere Webseite besuchen. <br/>
            <br/>Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie
            Informationen und Anleitungen, wie diese Cookies gelöscht oder deren Speicherung vorab blockiert werden
            können. Je nach Anbieter Ihres Browsers finden Sie die notwendigen Informationen unter den nachfolgenden
            Links:<br/>
            <br/>
            <br/>Mozilla Firefox: <a
            href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen" target="_blank"
            rel="nofollow noopener">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a>
            <br/>
            <br/>Internet Explorer: <a
            href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
            target="_blank"
            rel="nofollow noopener">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a>
            <br/>
            <br/>Google Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank"
                                   rel="nofollow noopener">https://support.google.com/accounts/answer/61416?hl=de</a>
            <br/>
            <br/>Opera: <a href="http://www.opera.com/de/help" target="_blank"
                           rel="nofollow noopener">http://www.opera.com/de/help</a><br/>
            <br/>Safari: <a href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE" target="_blank"
                            rel="nofollow noopener">https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a>
            <br/>
            <br/>
            <h3>Speicherdauer und eingesetzte Cookies:</h3>
            <br/>Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von Cookies erlauben,
            können folgende Cookies auf unseren Webseiten zum Einsatz kommen:<br/>
            <br/>Technisch notwendige Cookies zur Speicherung der Einverständniserklärung bzgl. der Cookienutzung und
            zur Erhebung anonymisierter Nutzungsstatistiken (Speicherdauer: bis zur Löschung durch den User).<br/>
            <h2>Technisch notwendige Cookies </h2>
            <h3>Art und Zweck der Verarbeitung: </h3>
            <br/>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer
            Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden
            kann.<br/>
            <br/>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu
            vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten
            werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt
            wird.<br/>
            <br/>Für folgende Anwendungen benötigen wir Cookies:<br/>
            <br/><br/><h3>Rechtsgrundlage und berechtigtes Interesse: </h3>
            <br/>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an
            einer nutzerfreundlichen Gestaltung unserer Website.<br/>
            <h3>Empfänger: </h3>
            <br/>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer
            Website als Auftragsverarbeiter tätig werden.<br/>
            <br/><br/><h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <br/>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich
            vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
            gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein.<br/>
            <h3>Widerspruch</h3>
            <br/>Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO weiter unten.<br/>
            <br/><br/><h2>Technisch nicht notwendige Cookies</h2>
            <br/>Des Weiteren setzen wir Cookies ein, um das Angebot auf unserer Website besser auf die Interessen unserer
            Besucher abzustimmen oder auf Basis statistischer Auswertungen allgemein zu verbessern.<br/>
            <br/>Welche Anbieter Cookies setzen, entnehmen Sie bitte den unten aufgeführten Informationen zu den
            eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.<br/>
            <h3>Rechtsgrundlage:</h3>
            <br/>Rechtsgrundlage für diese Verarbeitungen ist jeweils Ihre Einwilligung, Art. 6 Abs. 1 lit. a DSGVO.<br/>
            <h3>Empfänger:</h3>
            <br/>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer
            Website als Auftragsverarbeiter tätig werden. <br/>
            <br/>Weitere Empfänger entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten
            Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.<br/>
            <h3>Drittlandtransfer:</h3>
            <br/>Informationen hierzu entnehmen Sie bitte aus den Auflistungen der einzelnen Darstellungs-, Tracking-,
            Remarketing- und Webanalyse-Anbietern.<br/>
            <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <br/>Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Webbrowser sind
            regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von
            Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren (siehe Widerruf der
            Einwilligung).<br/>
            <br/>Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie
            die Verwendung von Cookies deaktiviert haben.<br/>
            <h3>Widerruf der Einwilligung:</h3>
            <br/>Sie können Ihre Einwilligung jederzeit über unser Cookie-Consent-Tool widerrufen. <br/>
            <h3>Profiling:</h3>
            <br/>Inwiefern wir das Verhalten von Websitebesuchern mit pseudonymisierten Nutzerprofilen analysieren,
            entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-,
            Remarketing- und Webanalyse-Technologien.<br/>
            <br/><br/><h2>Verwendung von Matomo</h2>
            <br/>Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website Matomo (vormals Piwik) eingesetzt,
            eine Open-Source-Software zur statistischen Auswertung von Besucherzugriffen. Anbieter der Software Matomo
            ist die InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland. <br/>
            <br/>Matomo setzt einen Cookie (eine Textdatei) auf Ihrem Endgerät, mit dem Ihr Browser wiedererkannt werden
            kann. Werden Unterseiten unserer Website aufgerufen, werden folgende Informationen gespeichert:<br/>
            <br/>
            <br/>die IP-Adresse des Nutzers, gekürzt um die letzten zwei Bytes (anonymisiert)<br/>
            <br/>die aufgerufene Unterseite und Zeitpunkt des Aufrufs<br/>
            <br/>die Seite, von der der Nutzer auf unsere Webseite gelangt ist (Referrer)<br/>
            <br/>welcher Browser mit welchen Plugins, welches Betriebssystem und welche Bildschirmauflösung genutzt
            wird
            <br/>
            <br/>die Verweildauer auf der Website<br/>
            <br/>die Seiten, die von der aufgerufenen Unterseite aus angesteuert werden<br/>
            <br/>
            <br/>Die Verwendung von Matomo erfolgt zu dem Zweck, die Qualität unserer Website und ihre Inhalte zu
            verbessern. Dadurch erfahren wir, wie die Website genutzt wird und können so unser Angebot stetig
            optimieren. <br/>
            <br/>Durch die Anonymisierung der IP-Adresse um sechs Stellen tragen wir dem Interesse des Webseitenbesuchers
            am Schutz personenbezogener Daten Rechnung. Die Daten werden nicht dazu genutzt, den Nutzer der Website
            persönlich zu identifizieren und werden nicht mit anderen Daten zusammengeführt. Die durch das Cookie
            erzeugten Informationen über Ihre Benutzung dieser Webseite werden nicht an Dritte weitergegeben.<br/>
            <br/><br/><h3>Widerruf der Einwilligung:</h3>
            <br/>Sie können Ihre Einwilligung zur Speicherung und Auswertung Ihrer Daten durch Matomo jederzeit über die unten stehende Checkbox erteilen oder widerrufen. Es wird dann ein sogenanntes Opt-Out-Cookie auf Ihrem Gerät
            gespeichert. Es hat zur Folge, dass wir mit Matomo keinerlei Sitzungsdaten erheben.
            Beachten Sie allerdings, dass das Opt-Out-Cookie gelöscht wird, wenn Sie alle Cookies löschen.<br/>
            <br/>
            <input type="checkbox"
                   checked={consent}
                   onChange={() => this.toggleConsent()}
            />
            Ich erteile meine Einwilligung zur Erhebung von anonymisierten Nutzungsdaten mittels Matomo.
            <br/>
            <br/>Nähere Informationen zu den Privatsphäre-Einstellungen der Matomo Software finden Sie unter folgendem
            Link: <a href="https://matomo.org/docs/privacy/" target="_blank"
                     rel="nofollow noopener">https://matomo.org/docs/privacy/</a>.<br/>
            <br/>Sie können die Verwendung von Cookies auch durch eine entsprechende Einstellung Ihrer Browser Software
            verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können.<br/>
            <br/>Wenn Sie in Ihrem Browser die "Do-not-track" Funktion aktiviert haben, oder ein Plugin wie "Ad-Block-Plus" nutzen, erheben wir trotz ggf. gesetzten Cookies keine Nutzungsdaten.<br/>
            <br/><br/><h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
            <br/>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf
            dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
            nachfolgend „Google“) zur Darstellung von Schriften.<br/>
            <br/>Weitere Informationen zu Google Web Fonts finden Sie unter <a
            href="https://developers.google.com/fonts/faq" rel="noopener nofollow"
            target="_blank">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a
            href="https://www.google.com/policies/privacy/" rel="noopener nofollow"
            target="_blank">https://www.google.com/policies/privacy/</a>.<br/>
            <br/><br/><h2>Eingebettete YouTube-Videos</h2>
            <br/>Auf unserer Website betten wir YouTube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube,
            LLC, 901 Cherry Ave., San Bruno, CA 94066, USA (nachfolgend „YouTube“). Die YouTube, LLC ist einer
            Tochtergesellschaft der Google LLC, 1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA (nachfolgend
            „Google“). Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von
            YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem
            YouTube-Account eingeloggt sind, kann YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies
            verhindern Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen. <br/>
            <br/>Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten
            sammeln. <br/>
            <br/>Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten
            Sie in den Datenschutzerklärungen des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren
            diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre (<a
            href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>). <br/>
            <h3>Widerruf der Einwilligung:</h3>
            <br/>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der
            Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
            wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende
            Cookie-Kategorie oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall
            können Sie unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.<br/>
            <br/><br/><h2>SSL-Verschlüsselung</h2>
            <br/>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der
            Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.<br/>
            <br/><br/>
            <hr/>
            <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
            <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
            <br/>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
            (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt
            auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.<br/>
            <br/>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn,
            wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
            Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.<br/>
            <h3>Empfänger eines Widerspruchs</h3>
            <br/>BLOMST!gUG
            <br/>Friedrich-Zundel-Straße 40
            <br/>70619 Stuttgart<br/>
            <hr/>
            <h2>Änderung unserer Datenschutzbestimmungen</h2>
            <br/>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
            Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
            z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue
            Datenschutzerklärung.<br/>
            <h2>Fragen an den Datenschutzbeauftragten</h2>
            <br/>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an
            die für den Datenschutz verantwortliche Person in unserer Organisation:<br/>
            <br/>BLOMST!gUG
            <br/>Nina Kurzeja
            <br/>Friedrich-Zundel-Straße 40
            <br/>70619 Stuttgart<br/>
            <br/><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a
            href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noopener">externe
            Datenschutzbeauftragte</a> (Version #2020-09-30).</em><br/>



          </div>
        </PageText>
      </ImprintContainer>
    );
  }
}